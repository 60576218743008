import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'
import { CircularProgress } from '@mui/material'
import { useContext, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button, Container, FileStatusMessage, FilesList, UploadContainer, Wrapper } from './style'
import { GlobalContext } from '../../../../../context/global/global'
import { uploadDemonstrative } from '../../../../../services/requests/user-requests'
import Modal from '../../../../../components/Modal'
import { ButtonsContainer } from '../../../../../components/Modal/PaymentExcel/styles'
import { useToast } from '../../../../../hooks/useToast'
import { CancelButton, SendButton } from '../../../../../components/Modal/Demonstrative/styles'

interface UploadDemonstrativesModalProps {
  onRefresh: () => void
  openModal: boolean
  setOpenModal: (open: boolean) => void
  referenceMonth: string
}

export function UploadDemonstrativesModal({
  referenceMonth,
  setOpenModal,
  openModal,
  onRefresh 
}: UploadDemonstrativesModalProps) {
  const { toast } = useToast()
  const { Theme } = useContext(GlobalContext)

  const [file, setFile] = useState<File | undefined>()
  const [sendDemonstrativeLoading, setSendDemonstrativeLoading] = useState<boolean>(false)
  const [distributor] = useState('copel')

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'text/html': ['.xlsx'],
    },
    onDrop: (files) => {
      setFile(files?.at(0))
    },
    maxFiles: 1
  })

  const handleDeleteAll = () => {
    setFile(undefined)
  }

  const handleUploadDemonstratives = async () => {
    if (!file) {
      toast({
        type: 'warning',
        message: 'Faltando arquivo para upload'
      })
      return
    }

    setSendDemonstrativeLoading(true)

    uploadDemonstrative({
      file,
      distributor,
      referenceMonth
    })
      .then(() => {
        toast({
          message: 'Upload feito com sucesso!',
          type: 'success'
        })

        setOpenModal(false)
      })
      .catch((err) => {
        const error = err as Error 

        toast({
          type: 'error',
          message: error?.message ?? "Ocorreu um erro ao fazer o upload do demonstrativo"
        })
      }).finally(() => {
        setSendDemonstrativeLoading(false)
        onRefresh()
      })
  }

  return (
    <Modal
      title='Upload de demonstrativo Micro/Mini Geração da Copel'
      openModal={openModal}
      handleOnClose={handleDeleteAll}
      setModalOpen={setOpenModal}
      defaultButtons={false}
    >
      <div>
        <UploadContainer {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
          <input {...getInputProps()} />
          <p>Arraste o arquivo ou clique para fazer o upload</p>
        </UploadContainer>
        <FilesList>
          {file && (
            <Wrapper>
              <Container>
                <DescriptionRoundedIcon />
                {file.name}
              </Container>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {sendDemonstrativeLoading && <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.orange}>
                  Enviando...
                </FileStatusMessage>}
                <Button onClick={() => handleDeleteAll()}>
                  <DeleteRoundedIcon />
                </Button>
              </div>
            </Wrapper>
          )}
        </FilesList>
      </div>

      <ButtonsContainer>
        <CancelButton
          disabled={sendDemonstrativeLoading}
          type='button'
          onClick={() => {
            setOpenModal(false)
            handleDeleteAll()
          }}
        >
          Fechar
        </CancelButton>

        <SendButton disabled={sendDemonstrativeLoading} onClick={handleUploadDemonstratives}>
          {sendDemonstrativeLoading ? <CircularProgress size={20} /> : 'Enviar'}
        </SendButton>
      </ButtonsContainer>
    </Modal>
  )
}
