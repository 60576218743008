import { useState } from 'react'

import { getAzureData, sendIaData } from '../../../services/requests/user-requests'

import { Button, Buttons, Container, Infos, Return, ShowData, Status } from './styles'
import { SendInvoice } from '../../../components/Pdfs/SendInvoice'
import ArrowBack from '@mui/icons-material/ArrowBack'

interface ReportNeoenergiaReaderProps {
  setPage: (value: string) => void
}

export function ReportNeoenergiaReader({ setPage }: ReportNeoenergiaReaderProps) {
  const [data, setData] = useState<any>({})
  const [closure, setClosure] = useState<any>({})
  const [exist, setExist] = useState(false)
  const [status, setStatus] = useState(false)
  const [hasMuc, setHasMuc] = useState(false)

  const [file, setFile] = useState<File>()

  async function uploadPDF() {
    if (file) {
      setStatus(true)
      const formDatas = new FormData()
      formDatas.append('files', file)

      await getAzureData('', hasMuc, undefined, file)
        .then((val) => {
          setData(val.invoice)
          setClosure(val.closure)
          setExist(val.exists)
        })
        .catch(err => alert(err))
        .finally(() => setStatus(false))
    } else {
      alert('PDF Ausente')
    }
  }

  async function uploadData() {
    if (Object.keys(data).length) {
      setStatus(true)

      const sendData = await sendIaData(data)
        .then(() => {
          setExist(false)
          setClosure({})
          setData({})
        })
        .catch((err) => alert(err))
        .finally(() => setStatus(false))

      console.log(sendData)
    } else {
      alert('Extraia os dados do PDF primeiro')
    }
  }

  return (
    <Container>
      <Infos>
        <Return
          onClick={() => {
            setPage('')
          }}
        >
          <ArrowBack fontSize='small' />
          <p>Voltar</p>
        </Return>
        <Buttons>
          <Button className='orange' onClick={() => setHasMuc(!hasMuc)}>
            {hasMuc ? '' : 'Não '}Incluir Muc - Clique para alterar
          </Button>
          {exist ? <Status>Esta fatura já existe</Status> : null}
          <Button onClick={uploadPDF}>Dados pdf</Button>
          <Button onClick={uploadData}>Enviar</Button>
        </Buttons>
      </Infos>
      <SendInvoice setFile={setFile} />
      {!status ? (
        <ShowData>
          <div>
            <h3>Fatura</h3>
            {Object.keys(data).map((val) => {
              return (
                <p key={val}>
                  <span>{val}</span>: {data[val]}
                </p>
              )
            })}
          </div>
          <div>
            <h3>Fechamento</h3>
            {Object.keys(closure).map((val) => {
              return (
                <p key={val}>
                  <span>{val}</span>: {closure[val]}
                </p>
              )
            })}
          </div>
        </ShowData>
      ) : (
        <p>Carregando dados...</p>
      )}
    </Container>
  )
}
