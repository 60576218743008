import { useContext, useEffect, useMemo, useState } from 'react'
import { ButtonContainer, TicketStatusText, TicketsContainer } from '../../styles'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useTickets } from '../../hooks/useTickets'
import Loading from '../../../../components/Loading'
import { GlobalContext } from '../../../../context/global/global'
import { FiltersContainer, RightSideContainer, TicketsCount } from './styles'
import StatusFilter from '../StatusFilter'
import ColumnFilterSelector from '../ColumnFilterSelector'
import CreateTicketButton from '../CreateTicketButton'
import { ActionTableButton } from '../../../../components/Table/ActionTableButton'
import { Cached } from '@mui/icons-material'
import { Theme } from '../../../../styles/theme'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Tooltip } from '@mui/material'
import { TicketStatus } from '../../../../interfaces/tickets'
import TicketModal from '../TicketModal'
import { format } from 'date-fns'
import { Sort } from '../../../../interfaces/conference'
import { TableV2 } from '../../../../components/TableV2'
import { TablePagination } from '../../../../components/TableV2/components/TablePagination'
import SearchInput from '../SearchInput'
import { FloatingFilterWrapper } from '../../../../components/FloatingFilterWrapper'
import MultiManagerSelect from '../MultiManagerSelect'
import CategoryFilter from '../CategoryFilter'
import SubCategoryFilter from '../SubCategoryFilter'
import { useSearchParams } from 'react-router-dom'
import AssigneeSelect from '../AssigneeSelect'

const defaultTableColumns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Título',
    hideable: true,
    sortable: true,
  },
  {
    field: 'category',
    headerName: 'Categoria',
    hideable: true,
    sortable: true,
  },
  {
    field: 'subCategory',
    headerName: 'Sub Categoria',
    width: 210,
    hideable: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      if (params.value) {
        return params.value
      }
      return '-'
    },
  },
  {
    field: 'createdAt',
    headerName: 'Data de abertura',
    hideable: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return format(params.value, 'dd/MM/yyyy')
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    hideable: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      const ticketStatusLabel: Record<TicketStatus, string> = {
        CLOSED: 'Concluido',
        OPEN: 'Aberto',
        IN_PROGRESS: 'Em progresso',
      }
      const ticketStatus = params.row.status as TicketStatus
      return (
        <TicketStatusText status={ticketStatus}>{ticketStatusLabel[ticketStatus]}</TicketStatusText>
      )
    },
  },
  {
    field: 'closedAt',
    headerName: 'Data de conclusão',
    hideable: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      if (params.value) {
        return format(params.value, 'dd/MM/yyyy')
      }
      return '-'
    },
  },
]

function getTableColumns(isMaster: boolean, onView: (ticketId: number) => void): GridColDef[] {
  return [
    {
      field: 'id',
      headerName: 'ID',
      hideable: true,
      sortable: true,
      width: 100,
    },
    ...(isMaster
      ? [
          {
            field: 'clientName',
            headerName: 'Cliente',
            hideable: true,
            sortable: true,
          },
          {
            field: 'assignedTo',
            headerName: 'Responsável',
            renderCell(params: GridRenderCellParams) {
              if (!params.value) {
                return '-'
              }
              return params.value
            },
          },
        ]
      : []),
    ...defaultTableColumns,
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      hideable: false,
      renderCell(params) {
        return (
          <Tooltip title={<p style={{ fontSize: 15 }}>Visualizar ticket</p>} placement='bottom'>
            <VisibilityIcon
              style={{ cursor: 'pointer', color: Theme.colors.grayDark }}
              fontSize='medium'
              onClick={() => onView(params.row.id)}
            />
          </Tooltip>
        )
      },
    },
  ]
}

export default function TicketsTable() {
  const { state } = useContext(GlobalContext)

  const { tickets, isLoading, fetchTickets } = useTickets()

  const [searchParams, setSearchParams] = useSearchParams()

  const [selectedTicketId, setSelectedTicketId] = useState<number>()

  const isMaster = state.auth?.customer?.type === 'MASTER'

  const defaultTableColumns = useMemo(() => getTableColumns(isMaster, setSelectedTicketId), [state])

  const [tableColumns, setTableColumns] = useState<GridColDef[]>(defaultTableColumns)

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [itemsPerPage, setItemsPerPage] = useState<number>(15)
  const [statusFilter, setStatusFilter] = useState<string>('')
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [assigneeFilter, setAssigneeFilter] = useState<string>('')
  const [categoryFilter, setCategoryFilter] = useState<string>('')
  const [subCategoryFilter, setSubCategoryFilter] = useState<string>('')
  const [selectedClientsIds, setSelectedClientsIds] = useState<number[]>([])
  const [sort, setSort] = useState<Sort>()

  const fetchParams = useMemo(
    () => ({
      page: currentPage,
      field: sort ? sort.field : undefined,
      order: sort ? sort.order : undefined,
      itemsPerPage,
      filter: {
        ...(selectedClientsIds.length > 0
          ? { client_id: selectedClientsIds.map((id) => String(id)).join(',') }
          : {}),
        ...(assigneeFilter ? { assignee: assigneeFilter } : {}),
        ...(searchFilter ? { search: searchFilter } : {}),
        ...(statusFilter ? { status: statusFilter } : {}),
        ...(subCategoryFilter ? { subCategory: subCategoryFilter } : {}),
        ...(categoryFilter ? { category: categoryFilter } : {}),
      },
    }),
    [
      itemsPerPage,
      assigneeFilter,
      selectedClientsIds,
      currentPage,
      sort,
      searchFilter,
      statusFilter,
      categoryFilter,
      subCategoryFilter,
    ],
  )

  useEffect(() => {
    const idNumber = parseInt(searchParams.get('id') ?? '', 10)
    if (idNumber && !isNaN(idNumber)) {
      setSelectedTicketId(idNumber)
    }
  }, [searchParams])

  useEffect(() => {
    fetchTickets(fetchParams)
  }, [fetchParams])

  return (
    <>
      {selectedTicketId && (
        <TicketModal
          ticketId={selectedTicketId}
          onTicketClose={() => {
            setCurrentPage(1)
            fetchTickets({ ...fetchParams, page: 1 })
          }}
          onClose={() => {
            setSearchParams((previousSearchParams) => {
              const updatedSearchParams = new URLSearchParams(previousSearchParams)
              updatedSearchParams.delete('id')
              return updatedSearchParams
            })
            setSelectedTicketId(undefined)
          }}
        />
      )}
      <TicketsContainer>
        <ButtonContainer>
          <FiltersContainer>
            {isMaster && (
              <FloatingFilterWrapper isFilterActive hideSubmitButton label='Clientes'>
                <MultiManagerSelect
                  selectedMangersIds={selectedClientsIds}
                  onChange={(managers) =>
                    setSelectedClientsIds(managers.map((manager) => manager.id))
                  }
                />
              </FloatingFilterWrapper>
            )}
            {isMaster && (
              <AssigneeSelect assignee={assigneeFilter} setAssigneeFilter={setAssigneeFilter} />
            )}
            <StatusFilter
              statusFilter={statusFilter}
              setStatusFilter={(value) => {
                setStatusFilter(value)
                setCurrentPage(1)
              }}
            />
            <CategoryFilter categoryFilter={categoryFilter} setCategoryFilter={setCategoryFilter} />
            <SubCategoryFilter
              subCategoryFilter={subCategoryFilter}
              setSubCategoryFilter={setSubCategoryFilter}
            />
          </FiltersContainer>

          <RightSideContainer>
            <TicketsCount>Total de tickets: {tickets.pageInfo.totalCount}</TicketsCount>
            <SearchInput onSearch={(search) => setSearchFilter(search)} />
            <ColumnFilterSelector
              tableColumns={tableColumns}
              onFilter={(checkboxes, itemsPerPage) => {
                setItemsPerPage(itemsPerPage)
                setTableColumns((previousColumns) =>
                  [...previousColumns].map((col) => {
                    const checkbox = checkboxes.find(
                      (checkbox) => checkbox.label === col.headerName,
                    )
                    return {
                      ...col,
                      hide: checkbox ? !checkbox.checked : false,
                    }
                  }),
                )
              }}
            />
            <CreateTicketButton />
            <ActionTableButton
              onClick={() => fetchTickets({ ...fetchParams, page: 1 })}
              icon={<Cached />}
            >
              Atualizar
            </ActionTableButton>
          </RightSideContainer>
        </ButtonContainer>

        {isLoading ? (
          <Loading />
        ) : (
          <TableV2
            columns={tableColumns}
            rows={tickets.data.map((data) => ({
              ...data,
              assignedTo: data.assigned_to,
              clientName: data.client.cliente_nome,
            }))}
            alignText='center'
            messageNoRow='Não há tickets cadastrados.'
            sort={sort}
            onSort={(sort) => {
              setSort(sort)
              setCurrentPage(1)
            }}
            serverSorting
          >
            <TablePagination
              pageInfo={tickets.pageInfo}
              currentPageItemCount={tickets.data.length}
              onChange={(page) => setCurrentPage(page)}
            />
          </TableV2>
        )}
      </TicketsContainer>
    </>
  )
}
